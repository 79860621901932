import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center square"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/5adaaf4fd77749165252b61dd011a6c0/2bef9/Morne_A_humanoid_monster_made_of_an_oily_sludge_with_gear-like__a7f7066f-f701-4ff3-9d9d-4d3b67bf0cb2.png",
            "srcSet": ["/static/5adaaf4fd77749165252b61dd011a6c0/5ff7e/Morne_A_humanoid_monster_made_of_an_oily_sludge_with_gear-like__a7f7066f-f701-4ff3-9d9d-4d3b67bf0cb2.png 375w", "/static/5adaaf4fd77749165252b61dd011a6c0/1d69c/Morne_A_humanoid_monster_made_of_an_oily_sludge_with_gear-like__a7f7066f-f701-4ff3-9d9d-4d3b67bf0cb2.png 750w", "/static/5adaaf4fd77749165252b61dd011a6c0/2bef9/Morne_A_humanoid_monster_made_of_an_oily_sludge_with_gear-like__a7f7066f-f701-4ff3-9d9d-4d3b67bf0cb2.png 1024w"],
            "width": "400px",
            "className": "right center square"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Etnu as it appeared in the Bathyslab`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p><em parentName="p">{`Hmm... a Worldsphere...  strange I did not feel it before.... so the Dominion has touched this place. Make a deal with me, mortals. Swear to me an oath such that I might be bound to this place. I can show you things far beyond your current understanding. The Edicts, Vestiges, The First Principles,  The very Axioms themselves. Do not bind yourselves to such Euclidean Prescripts.  Align with Etnu, we will help you transcend this reality.`}</em></p>
    <div style={{
      "textAlign": "right"
    }}>—Etnu</div>
    <p><strong parentName="p">{`Etnu`}</strong>{` is an entity that comes from `}<a parentName="p" {...{
        "href": "/Altrement",
        "title": "Altrement"
      }}>{`The Dark Between`}</a>{`, although it calls it the Altrement.`}</p>
    <h3>{`The Bathyslab`}</h3>
    <p>{`Etnu appears to `}<a parentName="p" {...{
        "href": "/Sulazar%20Stillwater",
        "title": "Sulazar Stillwater"
      }}>{`Sully`}</a>{`, `}<a parentName="p" {...{
        "href": "/Arlo%20Livingston",
        "title": "Arlo Livingston"
      }}>{`Arlo`}</a>{`, `}<a parentName="p" {...{
        "href": "/Eileen%20Quint",
        "title": "Eileen Quint"
      }}>{`Eileen`}</a>{`, and `}<a parentName="p" {...{
        "href": "/Lucian%20Cullpepper",
        "title": "Lucian Cullpepper"
      }}>{`Lucian`}</a>{` in the `}<a parentName="p" {...{
        "href": "/Bathyslab",
        "title": "Bathyslab"
      }}>{`Bathyslab`}</a>{`, where `}<a parentName="p" {...{
        "href": "/Jurrin%20Tuls",
        "title": "Jurrin Tuls"
      }}>{`Jurrin Tuls`}</a>{` had his `}<a parentName="p" {...{
        "href": "/Thread",
        "title": "Thread"
      }}>{`Thread`}</a>{` severed in one of `}<a parentName="p" {...{
        "href": "/Enzo%20Quint",
        "title": "Enzo Quint"
      }}>{`Enzo's`}</a>{` experiments (the events of the `}<a parentName="p" {...{
        "href": "/Fourth%20Tinwheel",
        "title": "Fourth Tinwheel"
      }}>{`Fourth Tinwheel`}</a>{`). It offers the `}<a parentName="p" {...{
        "href": "/Third%20Tinwheel",
        "title": "Third Tinwheel"
      }}>{`Third Tinwheel`}</a>{` and a great many other promises, even alluding that it could return Sully's `}<a parentName="p" {...{
        "href": "/Thread",
        "title": "Thread"
      }}>{`Thread`}</a>{` to him in an attempt to bind him to `}<a parentName="p" {...{
        "href": "/Naos",
        "title": "Naos"
      }}>{`Naos`}</a>{`.`}</p>
    <p>{`Remaining unconvinced, Lucian uses `}<a parentName="p" {...{
        "href": "/Primal%20Aspects#gravity",
        "title": "Primal Aspects"
      }}>{`Gravity`}</a>{` to take the Tinwheel from Etnu, and they manage to escape unscathed, leaving the entity in the remnants of the Bathyslab at the bottom of the sea.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      